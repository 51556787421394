@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap');

div {
  font-weight: 400;
  font-family: "Noto Sans", sans-serif;
  font-optical-sizing: auto;
  font-variation-settings: "wdth" 100;
}


body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100svh;
  width: 100svw;
  overflow: hidden;
  background-color: #101010;
}



code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  /* Dark theme colors */
  --background-color: #151515;
  --background-secondary-color: #222222;
  --text-color: #fff;
  --accent-color: #ccc;
  --accent-color-secondary: #8a8a8a;
  --text_secondary-color: #cecece;
  --hover-color: #404040;
}





#preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999999;
  background-color: var(#070513); /* Ensure the variable is defined */
  /* background-image: url('./Assets/pre.svg'); */
  background-repeat: no-repeat;
  background-position: center;
}

#preloader-none {
  opacity: 0;
}


.loading-container{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
}

#preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999999;
  background-image: url('./Assets/pre.svg');
  background-repeat: no-repeat;
  background-position: center;
}