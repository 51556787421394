.subscribe-overlay {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #111111;
    padding: 20px;
    border-radius: 10px;
    width: 100%;
    max-width: 400px;
    border: 1px solid #ffffff;
    box-sizing: border-box;
    position: relative;
    z-index: 1;
    max-height: 90vh; /* Make the overlay take up to 90% of the viewport height */
    overflow-y: auto; /* Enable vertical scrolling */
}

.subscribe-overlay h2 {
    color: #fff;
    margin-bottom: 20px;
}

.subscription-form {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); /* Use grid layout */
    gap: 20px; /* Space between the grid items */
    align-items: start;
}

.subscription-form label {
    color: #fff;
    font-size: 16px;
}

.subscription-form input,
.subscription-form select {
    width: 200px;
    padding: 10px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    background-color: #fff;
    color: #000;
}
.subscription-form .button-container {
    grid-column: 1 / -1; 
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.subscribe-button {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

.subscribe-button:hover {
    background-color: #0056b3;
}
