  @property --gradient-angle {
    syntax: "<angle>";
    initial-value: 0deg;
    inherits: false;
  }
  
  :root {
    --clr-1: #0b1b46;
    --clr-2: #004aac;
    --clr-3: #13a3e6;
    --clr-4: #0041bb;
    --clr-5: #750092;
  }

  .progress-provider {
    position: fixed;
    top: 20px;
    right: 20px;
    padding: 10px 20px;
    transition: opacity 0.5s ease-in-out;
  }
  
  .card {
    height: 70px;
    width: 180px;
    background: #000000;
    border-radius: 1rem;
    position: relative; 
    justify-content: center;
    align-items: center;
    display: flex;
    text-align: center;
    color: rgb(241, 241, 241);
    
  }
  
  .card::before,
  .card::after {
    content: "";
    position: absolute;
    inset: -0.5rem;
    background: conic-gradient(
      from var(--gradient-angle),
      var(--clr-3),
      var(--clr-4),
      var(--clr-5),
      var(--clr-4),
      var(--clr-3)
    );
    border-radius: inherit;
    z-index: -2; 
    animation: rotation 4s linear infinite;
  }
  
  .card::after {
    filter: blur(3.5rem);
  }
  
  @keyframes rotation {
    0% {
      --gradient-angle: 0deg;
    }
    100% {
      --gradient-angle: 360deg;
    }
  }

  .progress-provider.fade-in {
    opacity: 1;
  }
  
  .progress-provider.fade-out {
    opacity: 0;
  }