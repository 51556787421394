.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  animation: fadeIn 0.5s forwards;
  z-index: 10000;
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.overlayContent {
  margin-top: 20px;
  max-width: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  padding-right: 50px; /* Add padding to ensure the small box is visible */
  padding-left: 50px;
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
}

.overlayContent::-webkit-scrollbar { 
  display: none;  /* Safari and Chrome */
}

.overlay-media-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  position: relative; /* Add this to make the container a positioning context */
}

.subscription-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-actions-container {
  display: flex;
  align-items: flex-start; /* Ensure items are aligned to the top */
  justify-content: center;
  width: 100%;
}

.overlay-fullImage {
  max-height: 70vh;
  max-width: 100%;
  border-radius: 10px;
  margin-bottom: 20px;
}

.overlay-prompt {
  padding: 10px;
  background-color: rgba(94, 94, 94, 0.178);
  color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  word-wrap: break-word;
  overflow-wrap: break-word;
  text-align: center;
  max-width: 90%;
  overflow: auto;
}

.actions {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-left: 10px; /* Adjust this margin as needed */
}

.actions .button {
  height: 40px;
  width: 40px;
  border: #ffffff 2px solid;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px; /* Add margin between buttons */
}

.actions .button-icon {
  color: #ffffff;
}

.actions .button:hover {
  background-color: #5a5a5a;
}

video {
  max-height: 70vh;
  max-width: 100%;
  border-radius: 10px;
  margin-bottom: 20px;
}

.delete-confirmation {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #111111;
  padding: 50px;
  border-radius: 10px;
  width: 100%;
  max-width: 400px;
  border: rgb(255, 255, 255) 1px solid;
  box-sizing: border-box;
}

.delete-confirmation .button {
  background-color: #3f3f3f;
  color: white;
  border: white 1px solid;
  border-radius: 10px;
  padding: 20px;
  cursor: pointer;
  margin-top: 30px;
  margin-left: 10px;
  margin: 10px;
}

.delete-confirmation .button:hover {
  background-color: #8c8c8c;
}

.delete-confirmation .buttons-div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 10px;
}
