.trackContainer {
  width: 100%;
  border: 2px solid white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #f0f0f0;
  border-radius: 20px;
  cursor: pointer;
  padding: 10px;
  box-sizing: border-box;
  position: relative;
  background-color: rgba(255, 255, 255, 0.01);
}

.trackContainer.singleInstance {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.trackContainer .grid {
  height: auto;
  transition: transform 0.3s ease, background-color 0.3s ease;
}

.trackContainer:hover {
  transform: scale(1.05);
  background-color: rgba(158, 158, 158, 0.3);
}

.customAudioPlayer {
  position: relative;
  width: 100px;
  height: 100px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.customAudioPlayer audio {
  display: none;
}

.progressCircle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80px;
  height: 80px;
  visibility: hidden;
}

.progressCircle.visible {
  visibility: visible;
}

.progress-circle {
  fill: none;
  stroke: grey;
  stroke-width: 5;
  transition: stroke-dashoffset 0.35s;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
}

.playPauseButton {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 2em;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

label {
  text-align: center;
  color: white;
  margin-top: 5px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.infoIconContainer {
  position: absolute;
  top: 5px;
  right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.infoIcon {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  color: #ffffff;
  border: #ffffff solid 1px;
  text-align: center;
  line-height: 20px;
  cursor: pointer;
  position: relative;
}

.tooltip {
  visibility: hidden;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s;
}

.infoIconContainer:hover .tooltip {
  visibility: visible;
  opacity: 1;
}
